import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { LicenseInfo } from "@mui/x-license-pro";
import { ConfigProvider } from "Context/ConfigProvider";
import Auth0ProviderWithHistory from "auth/auth0-provider-with-history";
import AppLoading from "components/AppLoading/AppLoading";
//import { BrowserRouter } from "react-router-dom";
//import { Router, Route, browserHistory, Redirect } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { SnackbarProvider } from "notistack";
import SnackBar from "components/SnackBar/SnackBar";
import Layout from "layout/Layout";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

LicenseInfo.setLicenseKey(
  //"e2a4fd2bb464ec433a11c42f6c713fb5Tz03MTg5NSxFPTE3MjI1NDMxODgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
  "5cc473e1b5ae1357f6802e207aa707d3Tz05NTcwMSxFPTE3NTQ1ODUzNjUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

export const CUBEJS_API_URL = process.env.REACT_APP_CUBEJS_URL;

const errorLoading = (err) => {
  console.error("Dynamic page loading failed", err); // eslint-disable-line no-console
};

export default function Root(props) {
  const currentUser = useRef(sessionStorage.getItem("sensors-currentUser"));

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/u\/(\d+)/);
    if (match) {
      setUserId(match[1]);
    } else if (currentUser.current) {
      setUserId(currentUser.current);
    } else {
      setUserId(0);
    }
  }, [currentUser]);

  if (userId === null) return <AppLoading text="Cargando..." />;

  return (
    <Suspense fallback={<AppLoading text="Cargando..." />}>
      <Auth0ProviderWithHistory>
        <ConfigProvider>
          <SnackbarProvider
            Components={{
              snackbar: SnackBar,
            }}
          >
            <Router basename={`/u/${userId ?? 0}`}>
              <Layout>
                <Switch>
                  <Route
                    path="/visits"
                    component={lazy(() =>
                      import("./pages/Visits/Visits").catch(errorLoading)
                    )}
                  />
                  <Route
                    path="/admin/stores"
                    component={lazy(() =>
                      import("./pages/Admin/Admin").catch(errorLoading)
                    )}
                  />
                  {/*   <Route
                    path="/admin/sensors"
                    component={lazy(() =>
                      import("./pages/Admin/Sensors/Sensors").catch(
                        errorLoading
                      )
                    )}
                  /> */}
                  <Route
                    path="/admin/users"
                    component={lazy(() =>
                      import("./pages/Admin/Users/Users").catch(errorLoading)
                    )}
                  />
                  <Route
                    path="/api"
                    component={lazy(() =>
                      import("./pages/Api/Api").catch(errorLoading)
                    )}
                  />
                  <Route
                    path="/u/:userId/*"
                    render={({ match }) => {
                      const { userId } = match.params;
                      const realPath = match.url.split(`/u/${userId}/`)[1];
                      return <Redirect to={`/${realPath}`} />;
                    }}
                  />
                  <Redirect exact from="/" to="/visits" />
                  <Route
                    path="*"
                    component={lazy(() =>
                      import("./components/NotFoundPage/NotFoundPage").catch(
                        errorLoading
                      )
                    )}
                  />
                </Switch>
              </Layout>
            </Router>
          </SnackbarProvider>
        </ConfigProvider>
      </Auth0ProviderWithHistory>
    </Suspense>
  );
}
ReactDOM.render(<Root />, document.getElementById("root"));
